@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');


*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: var(--Inter);
}

:root{
  --Inter: 'Inter', sans-serif;
  --primaryGray: #5C5C5C;
  --primaryBlue: #2E80EB;
}

Nav{
  font-weight: 600;
  background-color: white ;
  border-bottom: 1px solid #c2c2c271;
} 

html {
  background-color: #f2f2f2;
}


/* Custom Heading */
h1{
  font-weight: 700;
  line-height: 1.5;
  font-family: var(--Inter);
}

.sectionPadding{
  padding: 100px 0px;
}

/* Custom Button */
.customButton1 {
  padding: 4px 35px;
  font-family: var(--Inter);
  text-transform: capitalize;
  border: 1px solid var(--primaryBlue);
  color: var(--primaryBlue);
  border-radius: 5px;
  transition: 0.2s;
}
.customButton1:hover{
  color: white;
  background-color: var(--primaryBlue);
  transition: 0.2s;
}
.customButton2 {
  padding: 4px 35px;
  font-family: var(--Inter);
  text-transform: capitalize;
  border: 1px solid var(--primaryBlue);
  color: var(--primaryBlue);
  border-radius: 5px;
  transition: 0.2s;
}
.customButton2:hover{
  color: white;
  background-color: var(--primaryBlue);
  transition: 0.2s;
}
.customButton3 {
  font-family: var(--Inter);
  font-size: 1rem;
  
}
.customButton3:hover{
  color: var(--primaryBlue);
  background-color: white;
  transition: 0.2s;
}
/* Custom Component */
.stripBlue{
  background-color: var(--primaryBlue);
  height: 3px;
  width: 150px;
}

.colorBlue{
  color: #2E80EB;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .imageLanding{
    width: 100%;
    height: auto;
  }
  .text-center-mobile{
    text-align: center;
  }
  .dnone-mobile{
    
  }
  .sectionPadding{
    padding: 50px 0px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}
